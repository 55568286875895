<template>
  <div class="w-full pt-5 pb-5">
    <div style="height:100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="flex">
        <back-button
          label="Back"
          @onClick="
            $router.push({
              name: 'Overtime',
              query: { type: $route.query.type }
            })
          "
          variant="secondary"
          class="mr-4"
        />
        <div
          class="mt-4"
          style="border-left: 1px solid #878E99; height:1.5rem"
        />

        <div style="">
          <h1 class="text-xl text-left font-extrabold capitalize ml-4 mt-3">
            {{ $route.query.name }}
          </h1>
        </div>

        <div class="w-7/12 mt-4 ml-4 capitalize">
          <Breadcrumb :items="breadcrumbs" />
        </div>
      </div>
      <div class="px-3">
        <employee-detail :detail="employeeDetail" />
      </div>
      <div class="px-3">
        <card class="p-5" style="margin-top: 4px;">
          <div class="w-full flex justify-end ">
            <Button
              background-color="none"
              class="text-darkPurple ml-4 text-l"
              border="none"
              @click="openModal = true"
            >
              <div class="flex items-center">
                <icon
                  class-name="text-flame"
                  icon-name="icon-message-outline"
                  size="xsm"
                />
                <p class="ml-4 text-flame">
                  Comments
                </p>
              </div>
            </Button>
          </div>

          <div class="w-full border h-0 border-dashed" />

          <card-footer />
        </card>

        <div class="flex">
          <Table
            :headers="overtimeHeader"
            :items="overtimeItems"
            class="w-full mt-4 mb-4"
            :loading="false"
          >
            <template v-slot:item="{ item }">
              <div v-if="item.paySetting">
                <span> {{ item.data.paySetting.nameOnPayslip }} </span>
              </div>
              <div v-else-if="item.startDate">
                <span class="text-sm font-semibold">
                  {{
                    $DATEFORMAT(new Date(item.data.startDate), "dd MMMM, yyyy")
                  }}
                </span>
              </div>
              <div v-else-if="item.endDate">
                <span class="text-sm font-semibold">
                  {{
                    $DATEFORMAT(new Date(item.data.endDate), "dd MMMM, yyyy")
                  }}
                </span>
              </div>
            </template>
          </Table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Button from "@/components/Button";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";
import EmployeeDetail from "../benefitUtilities/employeeDetail";

export default {
  name: "OvertimeDetails",
  components: {
    BackButton,
    Breadcrumb,
    Icon,
    Card,
    Button,
    Table,
    CardFooter,
    EmployeeDetail
  },
  data() {
    return {
      overtimeHeader: [
        { title: "Category", value: "paySetting" },
        { title: "Work Title", value: "plannedTask" },
        { title: "Description", value: "description" },
        { title: "Start Date", value: "startDate" },
        { title: "End Date", value: "endDate" },
        { title: "Rate/Hr", value: "ratePerHour" }
      ],
      breadcrumbs: [
        {
          disabled: false,
          text: "Benefits",
          id: ""
        },
        {
          disabled: false,
          text: "Overtime",
          id: "Overtime"
        },
        {
          disabled: false,
          text: this.$route.query.name,
          id: ""
        }
      ],
      overtimeItems: [],
      employeeDetail: {},
      loading: true,
      moreIcon: "more_icon",
      doccycle: "doc-cycle",
      calendarIcon: "calendar",
      reviewIcon: "reviewicon",
      starreviewIcon: "star-review",
      iconInfo: "icon-info",
      iconPlus: "icon-plus",
      iconCopy: "icon-copy",
      iconEdit: "icon-edit",
      iconChevron: "chevronRight"
    };
  },
  methods: {
    async getOverTImeDetails() {
      if (this.$route.query.overtimeType === "overtimePay") {
        try {
          const {
            data: { requests, user, work }
          } = await this.$_getPayRequestWork({
            userId: this.$route.params.id,
            query: this.$route.query.type
          });
          this.overtimeItems = requests;
          this.employeeDetail = { ...user, work };
          this.loading = false;
        } catch (error) {
          throw new Error(error);
        }
      } else {
        try {
          const {
            data: { requests, user, work }
          } = await this.$_getPlannedWorks({
            employeeId: this.$route.params.id,
            query: this.$route.query.type
          });
          this.overtimeItems = requests;
          this.employeeDetail = { ...user, ...work };
          this.loading = false;
        } catch (error) {
          throw new Error(error);
        }
      }
    }
  },
  mounted() {
    this.getOverTImeDetails();
  }
};
</script>

<style></style>
